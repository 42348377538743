import { install } from 'ga-gtag';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { I18nProvider } from '@semcore/utils/lib/enhances/WithI18n';
import * as Sentry from '@sentry/react';

import { store } from '@/app/appStore';
import { ThemeProvider } from '@/features/theme-switch/ThemeProvider';
import { config } from '@/shared/lib';

import router from './router';

import './styles/base.css';

Sentry.init({
  dsn: 'https://ce67ab19b4b34736398b10b836146d1f@o1131525.ingest.us.sentry.io/4507293947002880',
  environment: config.ENVIRONMENT,
  enabled: ['production', 'rc'].includes(config.ENVIRONMENT),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: ['Non-Error promise rejection captured'],
});

if (config.GTM_ID) install(config.GTM_ID);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <ThemeProvider>
      <I18nProvider value="en-GB">
        <RouterProvider router={router} />
      </I18nProvider>
    </ThemeProvider>
  </Provider>
);
