import { Outlet } from 'react-router-dom';

import Forward from '@/features/forwarder';

export default function AuthLayout() {
  return (
    <Forward>
      <main className="min-h-screen bg-paper font-outfit text-primary transition-colors duration-300 ease-out">
        <Outlet />
      </main>
    </Forward>
  );
}
