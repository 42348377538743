const userTypes = ['publisher', 'advertiser'] as const;

export type UserTypes = (typeof userTypes)[number];

export const userTypesMap = userTypes.reduce(
  (acc, userType) => ({ ...acc, [userType]: userType }),
  {} as Record<(typeof userTypes)[number], (typeof userTypes)[number]>
);

export default userTypes;
