import { userTypesMap } from '@/shared/lib/constants/userTypes';

import { config } from '../lib';

import { createApiFor } from './createApi';

const advertiserApi = createApiFor(
  userTypesMap.advertiser,
  `https://${config.API_ENDPOINT}`
);

export default advertiserApi;
