import { config } from '@/shared/lib';
import { useTheme } from '@/features/theme-switch';

// eslint-disable-next-line import/no-absolute-path
import logoDark from '/logo-dark.svg?url';
// eslint-disable-next-line import/no-absolute-path
import logo from '/logo-full.svg?url';

const LogoText = ({
  className = 'w-[86px] h-[24px]',
}: {
  className?: string;
}) => {
  const { darkTheme } = useTheme();
  const domain = localStorage.getItem('token')
    ? `pub.${config.ROOT_DOMAIN}`
    : config.ROOT_DOMAIN;

  return (
    <a href={`https://${domain}`} className={className}>
      <img
        style={{ width: '100%', height: '100%' }}
        src={darkTheme ? logoDark : logo}
        alt="logo"
      />
    </a>
  );
};
export default LogoText;
