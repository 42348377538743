import { combineReducers, configureStore } from '@reduxjs/toolkit';

import settingsSlice, {
  settingsSliceName,
} from '@/entities/settings/model/slice';
import advertiserApi from '@/shared/api/advertiserApi';
import publisherApi from '@/shared/api/publisherApi';

const rootReducer = combineReducers({
  [advertiserApi.reducerPath]: advertiserApi.reducer,
  [publisherApi.reducerPath]: publisherApi.reducer,
  [settingsSliceName]: settingsSlice,
} as const);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Skip serializability checks for Blob objects in getPaymentInvoice query
      serializableCheck: {
        ignoredActions: ['publisher/executeQuery/fulfilled'],
        ignoredPaths: [/publisher.queries.getPaymentInvoice.*/],
      },
    }).concat(advertiserApi.middleware, publisherApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type Slices = keyof RootState;

export type AppDispatch = typeof store.dispatch;
