import { useEffect } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { Button } from '@/shared';
import { redirectToMain } from '@/shared/lib';
import LogoText from '@/shared/ui/icons/LogoText';

export default function RootBoundary() {
  const error = useRouteError();
  let message = 'Something went wrong.';

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      message = "This page doesn't exist!";
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => redirectToMain(), 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <div className="fixed top-0 z-50 flex justify-start p-4">
        <LogoText className="h-[32px]" />
      </div>
      <div className="flex h-screen w-full flex-col content-center items-center justify-center">
        <div className="text-center text-[24px] text-blue md:text-[56px]">
          {message}
        </div>
        <Button
          onClick={() => redirectToMain()}
          title="Reload the page"
          variant="primary"
          size="xl"
          className="mt-4 w-[280px] md:w-[300px]"
        />
      </div>
    </>
  );
}
