import { z } from 'zod';

const envViteVariables = z.object({
  VITE_API_ENDPOINT: z.string(),
  VITE_SITE_NAME: z.string(),
  VITE_ROOT_DOMAIN: z.string(),
  VITE_REFERRAL_LINK: z.string(),
  VITE_TERMS_URL: z.string(),
  VITE_GOOGLE_ID_AUTH: z.string(),
  VITE_WITH_GOOGLE_AUTH: z.string().nullish(),
  VITE_CABINET_MAIN_PAGE: z.string(),
  VITE_WITH_FACEBOOK_AUTH: z.string().nullish(),
  VITE_THEME_STORAGE: z.string().nullish(),
  VITE_GTM_ID: z.string().nullish(),
});

const envVariables = z.object({
  API_ENDPOINT: z.string().nullish(),
  SITE_NAME: z.string().nullish(),
  REFERRAL_LINK: z.string().nullish(),
  TERMS_URL: z.string().nullish(),
  GOOGLE_ID_AUTH: z.string().nullish(),
  WITH_GOOGLE_AUTH: z.string().nullish(),
  CABINET_MAIN_PAGE: z.string().nullish(),
  WITH_FACEBOOK_AUTH: z.string().nullish(),
  // THEME_STORAGE: z.string().nullish(),
  GTM_ID: z.string().nullish(),
});

envViteVariables.parse(import.meta.env);
envVariables.parse(process.env);

declare global {
  interface ImportMetaEnv extends z.infer<typeof envViteVariables> {}
}

export const config = {
  ENVIRONMENT:
    process.env.ENVIRONMENT || import.meta.env.VITE_ENVIRONMENT || 'developer',
  API_ENDPOINT: process.env.API_ENDPOINT || import.meta.env.VITE_API_ENDPOINT,
  SITE_NAME: process.env.SITE_NAME || import.meta.env.VITE_SITE_NAME,
  REFERRAL_LINK:
    process.env.REFERRAL_LINK || import.meta.env.VITE_REFERRAL_LINK,
  ROOT_DOMAIN: process.env.ROOT_DOMAIN || import.meta.env.VITE_ROOT_DOMAIN,
  TERMS_URL: process.env.TERMS_URL || import.meta.env.VITE_TERMS_URL,
  GOOGLE_ID_AUTH:
    process.env.GOOGLE_ID_AUTH || import.meta.env.VITE_GOOGLE_ID_AUTH,
  WITH_GOOGLE_AUTH: Boolean(
    process.env.WITH_GOOGLE_AUTH ?? import.meta.env.VITE_WITH_GOOGLE_AUTH
  ),
  CABINET_MAIN_PAGE:
    process.env.CABINET_MAIN_PAGE || import.meta.env.VITE_CABINET_MAIN_PAGE,
  WITH_FACEBOOK_AUTH: Boolean(
    process.env.WITH_FACEBOOK_AUTH || import.meta.env.VITE_WITH_FACEBOOK_AUTH
  ),
  // THEME_STORAGE: Boolean(
  //   process.env.THEME_STORAGE || import.meta.env.VITE_THEME_STORAGE
  // ),
  GTM_ID: process.env.GTM_ID || import.meta.env.VITE_GTM_ID,
} as const;
