import { userTypesMap } from '@/shared/lib/constants/userTypes';

import { config } from '../lib';

import { createApiFor } from './createApi';

const publisherApi = createApiFor(
  userTypesMap.publisher,
  `https://${config.API_ENDPOINT}`
);

export default publisherApi;
