const index = '/';
const publisherPath = `${index}publisher`;
const advertiserPath = `${index}advertiser`;

export const publishers = {
  dashboard: `${publisherPath}/dashboard`,
  ads: `${publisherPath}/ads`,
  sites: `${publisherPath}/sites`,
  statistics: `${publisherPath}/statistics`,
  payments: `${publisherPath}/payments`,
  referrals: `${publisherPath}/referrals`,
  profile: `${publisherPath}/profile`,
  index: publisherPath,
} as const;

export const advertisers = {
  dashboard: `${advertiserPath}/dashboard`,
  campaigns: `${advertiserPath}/campaigns`,
  statistics: `${advertiserPath}/statistics`,
  payments: `${advertiserPath}/payments`,
  audience: `${advertiserPath}/audience`,
  lib: `${advertiserPath}/lib`,
  profile: `${advertiserPath}/profile`,
  index: advertiserPath,
} as const;

export const commons = {
  signin: '/signin',
  signup: '/signup',
  'login-as-user': '/login-as-user',
  'password-recovery': '/password-recovery',
  confirmation: '/confirmation',
  'not-verified': '/not-verified',
  'resend-email': '/resend-email',
  index,
} as const;
