import { Outlet } from 'react-router-dom';

import NoticeContainer from '@/shared/ui/NoticeContainer';

export default function RootLayout() {
  return (
    <>
      <NoticeContainer />
      <Outlet />
    </>
  );
}
