import { forwardRef, PropsWithChildren } from 'react';

import { type IRadio, Radio } from '@/shared';

export interface CardCheckboxProps extends PropsWithChildren, IRadio {}

const CardCheckbox = forwardRef<HTMLInputElement, CardCheckboxProps>(
  ({ label, children, checked, ...props }, ref) => (
    <label
      htmlFor={`${props.id ? `${props.id}` : ''}`}
      className={`flex flex-col gap-3 p-4 ${
        props.disabled ? '' : 'cursor-pointer'
      } rounded-lg border border-light-gray hover:border-gray ${
        checked ? 'border-gray' : ''
      }`}
    >
      <Radio
        label={label}
        checked={checked}
        type="radio"
        {...props}
        ref={ref}
      />
      {children}
    </label>
  )
);

export default CardCheckbox;
