import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';

export interface IRadio
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string | React.ReactNode;
}

const Radio = forwardRef<HTMLInputElement, IRadio>((props, ref) => {
  const { label, ...prop } = props;

  const labelDisabled = prop.disabled ? '!cursor-default text-gray' : '';

  return (
    <div className="flex gap-x-2">
      <input
        ref={ref}
        type="radio"
        className="relative mr-[20px] h-0 w-0 before:absolute before:top-0 before:h-[20px] before:w-[20px] before:cursor-pointer before:rounded-full before:border-[2px] before:border-gray before:bg-default before:text-[#234DFF] before:outline-0 before:transition-all before:duration-300 before:ease-out before:checked:border-[6px] before:checked:border-[#234DFF] before:hover:border-[#234DFF] before:focus:ring-0 before:active:border-[#234DFF] before:disabled:hover:cursor-default before:disabled:hover:border-gray"
        {...props}
      />
      {label && (
        <label
          htmlFor={`${prop.id ? `${prop.id}` : ''}`}
          className={`cursor-pointer text-[16px] font-light leading-[20px] ${labelDisabled}`}
        >
          {label}
        </label>
      )}
    </div>
  );
});

export default Radio;
