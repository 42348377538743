import queryString from 'query-string';

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { redirectToMain } from '../lib';

const baseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    headers: {
      Accept: 'application/json',
    },
    credentials: 'same-origin',
    paramsSerializer: (params) =>
      queryString.stringify(params, { arrayFormat: 'index' }),
    prepareHeaders: (headers) => {
      if (localStorage.getItem('token')) {
        headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
      }

      return headers;
    },
  });

const baseQueryWithLogout: (
  baseUrl: string
) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
  (baseUrl: string) => async (args, api, extraOptions) => {
    // USE THIS FOR MOCKING:
    // if (typeof args !== 'string' && args.url.includes('invoices')) {
    //   await new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       reject(new Error('Error'));
    //       // resolve({
    //       //   data: { message: 'Success' },
    //       // });
    //     }, 1000);
    //   });
    // }

    const result = await baseQuery(baseUrl)(args, api, extraOptions);
    if (
      result.error &&
      result.error.status === 401 &&
      localStorage.getItem('token')
    )
      redirectToMain();

    return result;
  };

export const createApiFor = (reducerPath: string, baseUrl: string) =>
  createApi({
    reducerPath,
    baseQuery: baseQueryWithLogout(baseUrl),
    tagTypes: [
      'Sites',
      'User',
      'Orders',
      'PaymentMethods',
      'Statistics',
      'AdsTxt',
    ],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    endpoints: (_) => ({}),
  });

export type BaseApi = ReturnType<typeof createApiFor>;
