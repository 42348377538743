import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';

export interface ICheckbox
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string | React.ReactNode;
}

const Checkbox = forwardRef<HTMLInputElement, ICheckbox>((props, ref) => {
  const { label, ...prop } = props;

  return (
    <div className="flex gap-x-2">
      <div>
        <input
          ref={ref}
          type="checkbox"
          className="h-[20px] w-[20px] cursor-pointer rounded-[4px] border-[2px] border-[#A0A8AA] bg-default text-[#234DFF] outline-0 transition-all duration-300 ease-out checked:border-[#234DFF] focus:ring-0"
          {...props}
        />
      </div>
      {label && (
        <div>
          <label
            htmlFor={`${prop.id ? `${prop.id}` : ''}`}
            className="cursor-pointer text-[16px] font-light leading-[20px]"
          >
            {label}
          </label>
        </div>
      )}
    </div>
  );
});

export default Checkbox;
