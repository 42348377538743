import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import ThemeContext from './ThemeContext';

const mediaSelector = '(prefers-color-scheme: dark)';

const initTheme = () => {
  const theme = localStorage.getItem('theme');
  if (theme) return theme === 'dark';
  return window.matchMedia(mediaSelector).matches;
};

interface IThemeProvider {
  children: React.ReactNode;
}

export const ThemeProvider = ({ children }: IThemeProvider) => {
  const [darkTheme, setDarkTheme] = useState<boolean>(initTheme());

  useLayoutEffect(() => {
    const handler = (e: MediaQueryListEvent) => {
      const newColorScheme = e.matches;
      setDarkTheme(newColorScheme);
    };

    window.matchMedia(mediaSelector).addEventListener('change', handler);

    return () => {
      window.matchMedia(mediaSelector).removeEventListener('change', handler);
    };
  });

  const toggleTheme = useCallback(() => {
    setDarkTheme(!darkTheme);
    localStorage.setItem('theme', !darkTheme ? 'dark' : 'light');
  }, [darkTheme]);

  useEffect(() => {
    document.documentElement.className = darkTheme ? 'dark' : '';
  }, [darkTheme]);

  const contextValue = useMemo(
    () => ({ darkTheme, toggleTheme }),
    [darkTheme, toggleTheme]
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};
