import { hours, week, weekLength } from '../config';

import { ScheduleValues } from './types';

export const initialValue = week.reduce(
  (acc, _, index) => ({ ...acc, [index]: {} }),
  {} as ScheduleValues
);

export const isWeekend = (dayIndex: number) => dayIndex >= weekLength - 2;

export const pickOne =
  (dayIndex: number, hour: number) => (prev: ScheduleValues) => ({
    ...prev,
    [dayIndex]: prev[dayIndex][hour]
      ? { ...prev[dayIndex], [hour]: false }
      : { ...prev[dayIndex], [hour]: true },
  });

export const clearAll = () => initialValue;

export const pickAll = () =>
  week.reduce(
    (acc, _, index) => ({
      ...acc,
      [index]: hours.reduce(
        (checked, hour) => ({ ...checked, [hour]: true }),
        {}
      ),
    }),
    {} as ScheduleValues
  );

export const pickWorkingDays = () =>
  week.reduce(
    (acc, _, index) =>
      !isWeekend(index)
        ? {
            ...acc,
            [index]: hours.reduce(
              (checked, hour) => ({ ...checked, [hour]: true }),
              {}
            ),
          }
        : { ...acc, [index]: {} },
    {} as ScheduleValues
  );

export const pickWeekend = () =>
  week.reduce(
    (acc, _, index) =>
      isWeekend(index)
        ? {
            ...acc,
            [index]: hours.reduce(
              (checked, hour) => ({ ...checked, [hour]: true }),
              {}
            ),
          }
        : { ...acc, [index]: {} },
    {} as ScheduleValues
  );
