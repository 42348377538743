import { useEffect, useState } from 'react';

import userTypes, {
  type UserTypes,
  userTypesMap,
} from '@/shared/lib/constants/userTypes';
import includes from '@/shared/lib/utils/includes';

export default function useUserType() {
  const [item, setItem] = useState<UserTypes>(userTypesMap.publisher);

  useEffect(() => {
    const value = localStorage.getItem('user_type');

    if (value && includes(userTypes, value)) setItem(value);
  }, []);

  return item;
}
