import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useMemo,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { useTheme } from '@/features/theme-switch';
import Calendar from '@/shared/ui/icons/Calendar';

export interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  placeholder: string;
  error?: string;
  info?: React.ReactNode;
  icons?: ReactElement[];
  clear?: boolean;
  className?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ placeholder, error, icons, info, className, ...props }, ref) => {
    const { darkTheme } = useTheme();

    const bg = useMemo(
      () =>
        darkTheme
          ? `bg-[#FFFFFF14] hover:bg-[#FFFFFF29] focus:bg-[#FFFFFF] disabled:bg-[#FFFFFF0A] autofill:!bg-[#FFFFFF14] 
              border ${
                error
                  ? 'border-red'
                  : 'border-[#FFFFFF14] hover:border-[#FFFFFF29] focus:border-[#FFFFFF] disabled:border-[#FFFFFF0A]'
              }`
          : `bg-[#18192B05] hover:bg-[#FFFFFF] focus:bg-[#FFFFFF] disabled:bg-[#F8F8F8] autofill:!bg-[#18192B05]
              border ${
                error
                  ? 'border-red'
                  : 'border-[#FFFFFF14] hover:border-[#18192B3D] focus:border-[#234DFF] disabled:border-[#F8F8F8]'
              }`,
      [darkTheme, error]
    );

    return (
      <div className={twMerge('flex w-full flex-col gap-y-2', className)}>
        <div className="">
          <div className="relative z-10 flex w-full">
            {props.type === 'date' && (
              <Calendar className="pointer-events-none absolute right-4 top-4 z-50" />
            )}
            <input
              type="text"
              {...props}
              placeholder={placeholder}
              className={`${bg} peer h-14 w-full rounded-md px-5 pb-[11px] pt-[25px] text-[16px] font-light leading-[20px] text-primary outline-0
            transition-all duration-300 ease-out placeholder:text-primary
            placeholder:opacity-0
            placeholder-shown:py-[7px] focus:pb-[11px] focus:pt-[25px]
            focus:text-black focus:placeholder:text-black focus:placeholder:opacity-40`}
              ref={ref}
            />
            <label
              className={`pointer-events-none absolute left-[21px] top-[7px] text-[12px] font-medium
            leading-[16px] transition-all duration-300 ease-out peer-placeholder-shown:top-[18px]
            peer-placeholder-shown:text-[16px] peer-placeholder-shown:font-light peer-placeholder-shown:leading-[20px] 
            peer-placeholder-shown:text-[#8a8a8a] peer-autofill:top-[7px]
            peer-autofill:text-[12px] peer-autofill:font-medium peer-autofill:leading-[16px] peer-autofill:text-[#18192B66]
            peer-autofill:text-black peer-focus:top-[7px]
            peer-focus:text-[12px] peer-focus:font-medium peer-focus:leading-[16px] peer-focus:text-[#18192B66]`}
            >
              <span>{placeholder}</span>
              {props.required && <span className="text-blue">*</span>}
            </label>

            {icons && (
              <div className="absolute inset-y-0 right-[18px] flex items-center gap-x-2">
                {icons.map((icon, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`icon-${index}`} className="flex items-center">
                    {icon}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {info}
        <div
          className={`text-xs font-medium text-red
        ${error ? 'opacity-100' : 'opacity-0'}
        transition-all duration-300 ease-out`}
        >
          {error || 'no error'}
        </div>
      </div>
    );
  }
);

export default Input;
