import { createContext } from 'react';

export interface ThemeContextInterface {
  darkTheme: boolean;
  toggleTheme(): void;
}

const ThemeContext = createContext<ThemeContextInterface | null>(null);

export default ThemeContext;
