import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';

import { useTheme } from '@/features/theme-switch';

import Loader from '../Loader';

interface IButton
  extends Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'title'
  > {
  title: ReactNode;
  iconBefore?: ReactElement;
  isLoading?: boolean;
  iconAfter?: ReactElement;
  variant: 'primary' | 'secondary' | 'white' | 'function' | 'black';
  size: 'sm' | 'md' | 'xl';
}

const classes = {
  primary: {
    colors: {
      light: `text-white bg-blue hover:bg-black disabled:bg-[#A0A8AA] active:bg-[#292A3A]`,
      dark: `text-[#FFFFFFE0] bg-blue hover:bg-[#3D62FF] disabled:bg-[#FFFFFF52] disabled:text-[#FFFFFF8F] active:bg-[#0F3AED]`,
    },
    sizes: {
      sm: `text-[14px] leading-[16px] gap-x-0.5 px-3 py-1.5 rounded-[10px]`,
      md: `text-base gap-x-1 px-6 py-2 rounded-xl`,
      xl: `text-[18px] leading-[24px] gap-x-2 px-10 py-4 rounded-2xl`,
    },
    common: `flex items-center justify-center h-fit font-medium transition-all duration-300 ease-out`,
  },
  secondary: {
    colors: {
      light: `text-blue bg-[#234DFF00] hover:bg-[#1B42E30A] disabled:text-[#A0A8AA] active:bg-[#234DFF1F]`,
      dark: `text-[#FFFFFFE0] bg-[#234DFF00] hover:bg-[#FFFFFF14] disabled:text-[#FFFFFF8F] active:bg-[#FFFFFF1F]`,
    },
    sizes: {
      sm: `text-[14px] leading-[16px] gap-x-0.5 px-3 py-1.5 rounded-[10px]`,
      md: `text-base gap-x-1 px-6 py-2 rounded-xl`,
      xl: `text-[18px] leading-[24px] gap-x-2 px-10 py-4 rounded-2xl`,
    },
    common: `flex items-center justify-center h-fit font-medium transition-all duration-300 ease-out`,
  },
  white: {
    colors: {
      light: `text-white bg-[#FFFFFF29] hover:bg-[#FFFFFF52] disabled:bg-[#FFFFFF29] disabled:text-[#FFFFFF52] active:bg-[#FFFFFF66]`,
      dark: `text-white bg-[#FFFFFF29] hover:bg-[#FFFFFF52] disabled:bg-[#FFFFFF29] disabled:text-[#FFFFFF52] active:bg-[#FFFFFF66]`,
    },
    sizes: {
      sm: `text-[14px] leading-[16px] gap-x-0.5 px-3 py-1.5 rounded-[10px]`,
      md: `text-base gap-x-1 px-6 py-2 rounded-xl`,
      xl: `text-[18px] leading-[24px] gap-x-2 px-10 py-4 rounded-2xl`,
    },
    common: `flex items-center justify-center h-fit font-medium transition-all duration-300 ease-out`,
  },
  black: {
    colors: {
      light: `border bg-[#FFFFFF29] border-[#F2F4F6] hover:bg-[#FFFFFF52] disabled:bg-[#FFFFFF29] disabled:text-[#FFFFFF52] active:bg-[#FFFFFF66]`,
      dark: `border bg-transparent border-[#FFFFFF1F] hover:bg-[#FFFFFF52] disabled:bg-[#FFFFFF29] disabled:text-[#FFFFFF52] active:bg-[#FFFFFF66]`,
    },
    sizes: {
      sm: `text-[14px] leading-[16px] gap-x-0.5 px-3 py-1.5 rounded-[10px]`,
      md: `text-base gap-x-1 px-4 py-3 rounded-xl`,
      xl: `text-[18px] leading-[24px] gap-x-2 px-10 py-4 rounded-2xl`,
    },
    common: `flex items-center justify-center h-fit font-medium transition-all duration-300 ease-out`,
  },
  function: {
    colors: {
      light: `text-blue hover:text-[#18192B] disabled:text-[#A0A8AA] active:bg-[#292A3A]`,
      dark: `text-[#FFFFFF] disabled:text-[#FFFFFF8F] hover:text-[#aaaab1]`,
    },
    sizes: {
      sm: `text-[14px] leading-[16px] gap-x-0.5`,
      md: `text-base gap-x-1`,
      xl: `text-[18px] leading-[24px] gap-x-2`,
    },
    common: `flex items-center justify-center h-fit font-medium transition-all duration-300 ease-out`,
  },
};

export default function Button({
  title,
  iconAfter,
  iconBefore,
  variant,
  size,
  type = 'button',
  isLoading,
  className = '',
  disabled,
  ...props
}: IButton) {
  const { darkTheme } = useTheme();

  const styles = useMemo(() => {
    switch (variant) {
      case 'primary':
        return darkTheme
          ? `${classes.primary.common} ${classes.primary.colors.dark} ${classes.primary.sizes[size]}`
          : `${classes.primary.common} ${classes.primary.colors.light} ${classes.primary.sizes[size]}`;
      case 'secondary':
        return darkTheme
          ? `${classes.secondary.common} ${classes.secondary.colors.dark} ${classes.secondary.sizes[size]}`
          : `${classes.secondary.common} ${classes.secondary.colors.light} ${classes.secondary.sizes[size]}`;
      case 'white':
        return darkTheme
          ? `${classes.white.common} ${classes.white.colors.dark} ${classes.white.sizes[size]}`
          : `${classes.white.common} ${classes.white.colors.light} ${classes.white.sizes[size]}`;
      case 'black':
        return darkTheme
          ? `${classes.black.common} ${classes.black.colors.dark} ${classes.black.sizes[size]}`
          : `${classes.black.common} ${classes.black.colors.light} ${classes.black.sizes[size]}`;
      case 'function':
        return darkTheme
          ? `${classes.function.common} ${classes.function.colors.dark} ${classes.function.sizes[size]}`
          : `${classes.function.common} ${classes.function.colors.light} ${classes.function.sizes[size]}`;
      default:
        return darkTheme
          ? `${classes.primary.common} ${classes.primary.colors.dark} ${classes.primary.sizes[size]}`
          : `${classes.primary.common} ${classes.primary.colors.light} ${classes.primary.sizes[size]}`;
    }
  }, [darkTheme, size, variant]);

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      {...props}
      disabled={isLoading || disabled}
      className={`${styles} ${className}`}
    >
      {isLoading ? (
        <Loader size="sm" />
      ) : (
        iconBefore && <span>{iconBefore}</span>
      )}
      {title}
      {iconAfter && <span>{iconAfter}</span>}
    </button>
  );
}
