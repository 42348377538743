import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useCheckUserQuery } from '@/entities/auth';
import { userTypesMap } from '@/shared/lib/constants/userTypes';
import useUserType from '@/shared/lib/hooks/useUserType';
import PageLoader from '@/shared/ui/PageLoader';

const Forward = ({ children }: PropsWithChildren) => {
  const userType = useUserType();
  const { data, isError } = useCheckUserQuery(
    userType ?? userTypesMap.publisher
  )(userType, {
    refetchOnMountOrArgChange: true,
  });

  if (data) {
    return <Navigate to={`/${userType}/`} />;
  }

  return isError ? children : <PageLoader />;
};

export default Forward;
