import advertiserApi from '@/shared/api/advertiserApi';
import publisherApi from '@/shared/api/publisherApi';
import { UserTypes, userTypesMap } from '@/shared/lib/constants/userTypes';

import { authApi } from './api/authApi';

export const authApiPublisher = authApi(publisherApi);

export const authApiAdvertiser = authApi(advertiserApi);

const loginMutation = {
  [userTypesMap.advertiser]: authApiAdvertiser.useLoginMutation,
  [userTypesMap.publisher]: authApiPublisher.useLoginMutation,
} as const;

export const useLoginMutation = (userType: UserTypes) =>
  loginMutation[userType];

const socialLoginMutation = {
  [userTypesMap.advertiser]: authApiAdvertiser.useSocialLoginMutation,
  [userTypesMap.publisher]: authApiPublisher.useSocialLoginMutation,
} as const;

export const useSocialLoginMutation = (userType: UserTypes) =>
  socialLoginMutation[userType];

export const { useLoginAsUserMutation } = authApiPublisher;

const checkUserQuery = {
  [userTypesMap.advertiser]: authApiAdvertiser.useCheckUserQuery,
  [userTypesMap.publisher]: authApiPublisher.useCheckUserQuery,
} as const;

export const useCheckUserQuery = (userType: UserTypes) =>
  checkUserQuery[userType];

const logoutMutation = {
  [userTypesMap.advertiser]: authApiAdvertiser.useLogoutMutation,
  [userTypesMap.publisher]: authApiPublisher.useLogoutMutation,
} as const;

export const useLogoutMutation = (userType: UserTypes) =>
  logoutMutation[userType];

const createMutation = {
  [userTypesMap.advertiser]: authApiAdvertiser.useCreateMutation,
  [userTypesMap.publisher]: authApiPublisher.useCreateMutation,
} as const;

export const useCreateMutation = (userType: UserTypes) =>
  createMutation[userType];

const updateMutation = {
  [userTypesMap.advertiser]: authApiAdvertiser.useUpdateMutation,
  [userTypesMap.publisher]: authApiPublisher.useUpdateMutation,
} as const;

export const useUpdateMutation = (userType: UserTypes) =>
  updateMutation[userType];
