import { Currency } from '@/shared/types';

export const currencyDetails = {
  [Currency.USD]: {
    sign: '$',
    localization: 'en-US',
  },
  [Currency.RUB]: {
    sign: '₽',
    localization: 'ru-RU',
  },
  [Currency.EUR]: {
    sign: '€',
    localization: 'de-DE',
  },
} as const;
