import { createContext } from 'react';

export interface MenuDrawerContextInterface {
  openMenu: boolean;
  toggleMenu(): void;
  closeMenu(): void;
}

const MenuDrawerContext = createContext<MenuDrawerContextInterface | null>(
  null
);

export default MenuDrawerContext;
