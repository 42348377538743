import { SVGProps } from 'react';

const Calendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    stroke="#A0A8AA"
    {...props}
  >
    <path
      stroke="current"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.058 3.333V6.08M7.941 3.334v2.745m7.88 3.431H4.178m11.643 0c-.143-3.917-1.598-5.147-5.822-5.147-4.223 0-5.678 1.23-5.821 5.147m11.643 0c.008.22.012.449.012.686 0 4.461-1.373 5.834-5.834 5.834-4.46 0-5.833-1.373-5.833-5.834 0-.237.004-.466.012-.686"
    />
  </svg>
);
export default Calendar;
