import {
  type NoticeBubbleInfoProps,
  type NoticeBubbleWarningProps,
  NoticeBubbleManager,
} from '@semcore/ui/notice-bubble';

const noticeManager = new NoticeBubbleManager();

export default noticeManager;

export const addNotice = (
  props: NoticeBubbleInfoProps | NoticeBubbleWarningProps
) => {
  noticeManager.add({
    initialAnimation: true,
    duration: 3000,
    ...props,
  });
};
